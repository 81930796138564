.ulev__team {
    display: flex;
    flex-direction: column;
    padding: 100px 4rem 4rem 4rem;
}

.ulev__team-head {
    width: 40%; 
    text-align: left;
    margin-bottom: 4rem; 
}

.ulev__team-head h1 {
    font-family: "Syne";
    color: white;
    font-weight: 600;
    font-size: 46px; 
    line-height: 52px;
    letter-spacing: -0.04rem;
    padding-bottom: 15px;
} 

.ulev__team-head p {
    font-family: var(--font-family-Medium);
    font-size: 20px; 
    line-height: 25px;
    font-weight: 400;
    color: #ffffff;
}

/* .ulev__info-container h4 {
    font-family: 'Syne';
    font-weight: 400;
    line-height: 25px;
    font-size: 20px;
} */

.ulev__team-container {
    display: flex;
    flex-direction: column-reverse; 
}

.ulev__team-container_group {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)); 
    grid-gap: 1.5rem;
}

.ulev__team-container_group:hover {
    color: #888;
}
/* @media screen and (max-width: 768px) {
    .ulev__team-heading {
        font-size: 36px;
        line-height: 42px;
    }

    .ulev__team-container_group {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    }
} */

@media screen and (max-width: 990px) {
    .ulev__team-container {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 768px) {
    .ulev__team-container_group{
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    } 

    .ulev__team-head {
        font-size: 46px;
        line-height: 52px;
    }
} 

@media screen and (max-width: 768px) {
    .ulev__team-head {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .ulev__team-head {
        width: 100%;
    }
    .ulev__team {
        padding: 3rem 1rem 4rem 1rem;
    }
}




