.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: #000 !important;
  border-radius: 8px !important;
  padding: 20px !important;
  width: 1200px;
  max-width: 100% !important;
  max-height: 90% !important;
  overflow: auto !important;
}

/* .modal-content {
  background: #000000;
  padding: 30px 20px 20px 20px;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
} */

/* .flex-container {
  display: flex;
  flex-wrap: wrap;
} */

.wrapped-image {
  width: 300px;
  height: auto; 
  float: left;
  margin: 40px 40px 30px 0;
}

.unrapped-image {
  width: 100% !important;
  height: auto;
  /* float: left; */
}

.text-container {
  flex: 1;
  padding: 10px; 
  color: white;
  /* width: calc(100% - 320px);  */
  box-sizing: border-box; 
}

.text-container p {
  font-family: 'Syne-Regular', sans-serif !important;
  font-weight: 400;
  color: #ffffff;
  width: 100%;
  box-sizing: border-box;
}

.modal-content h1 {
  font-family: 'Syne-Regular', sans-serif !important;
  color: #ffffff;
  padding: 20px 0;
}

.text-container h1{
  font-family: 'Syne', sans-serif !important;
  font-weight: 700;
  line-height: 25px;
  font-size: 35px;
  color: #ffffff;
  padding-bottom: 25px;
}

.text-container p{
  color: #ffffff;
  font-family: 'Syne', sans-serif !important;
  font-weight: 400;
  line-height: 25px;
  font-size: 18px;
  padding-bottom: 25px;
}

.modal-content p {
  color: #ffffff;
  font-weight: 400;
  line-height: 25px;
  font-size: 18px;
  padding-bottom: 25px;
}

.close-button {
  color: white;
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.close-button-down {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #888;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto !important;
}



@media (max-width: 414px) {
  .modal-content {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
