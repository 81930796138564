.ulev_morePD {
    display: flex;
    flex-direction: column;
    background-color: rgb(12, 12, 12);
}
  
.ulev_morePD-feature h4 {
    font-family: var(--font-family);
    font-size: 30px;
    font-weight: 250;
    line-height: 30px;
    letter-spacing: 0;
    color: gray;
}
  
.ulev_morePD-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 2rem;
}
  
.ulev_morePD-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}
  
.ulev_morePD-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 1rem; 
}
  
.ulev_morePD-container {
    font-family: var(--font-family);
    font-size: 55px;
    font-weight: 250;
    line-height: 58px;
    text-align: right; 
    letter-spacing: 0;
    color: #ffffff;
}
  
.ulev_morePD-container h4 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
}
  
.explore__text {
    color: #ffffff;
}
  
@media screen and (max-width: 414px) {
    .ulev_morePD-container {
      text-align: left; 
    }
}
  