/* card.css */
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: black;
}

.card-header {
  border: 1px solid red;
  border-radius: 5px;
  overflow: hidden;
  width: 300px; 
  margin: 5px;
  height: 200px; 
  color: white;
}

.card-header {
  background-color: var(--card);
  padding: 10px;
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}

.card-description {
  color: gray;
}

.single-image-sec {
  width: 100%;
  /* margin-bottom: 20; */
  padding: 0 4rem 30px 4rem !important;
}

.single-image-sec img {
  width: 100%; 
}

@media (max-width: 1050px) {
  .cards {
    width: 45%; 
  }
}

@media (max-width: 768px) {
  .cards {
    width: 80%; 
  }
}

@media screen and (max-width: 414px) {
  .cards {
    width: 100%; 
  }
  .single-image-sec {
    width: 100%;
    /* margin-bottom: 20; */
    padding: 0 1rem 20px 1rem !important;
  }
}






/* blog */
.gpt__blog {
  display: flex;
  flex-direction: column;
}

.gpt__blog-feature {
  width: 60%;
}

.gpt__blog-feature h4 {
  font-family: 'Syne', sans-serif;
  font-style: normal;
  /* font-size: 90px; */
  font-size: 5.9vw;
  font-weight: 700;
  /* line-height: 100px; */
  line-height: 1.12;
  color: white;
  /* margin-bottom: 70px; */
  margin-bottom: 3.5vw;
  /* margin-top: 100px; */
  margin-top: 6.5vw;
}

.gpt__blog-heading {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: flex-start; 
  margin: 2rem 0; 
}

.gpt__blog-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: 'Syne', sans-serif;
  max-width: 100%; 
}

.gpt__blog-heading p {
  font-family: 'Syne', sans-serif;
  font-weight: 500;
  font-size: 18px; 
  line-height: 30px;
  color: #888;
  cursor: pointer;
  margin-top: 1rem; 
}

.gpt__blog-container {
  font-size: 16px;
  font-weight: 250;
  line-height: 30px;
  text-align: right; 
  color: #ffffff;
  width: 100%; 
  margin-left: auto;
  width: 40%;
  margin-top: 70px;
}

.gpt__blog-container h4 {
  font-family: var(--font-family-Medium);
  font-weight: 100 !important;
  line-height: 25px;
  font-size: 20px;


}

.explore__text {
  font-family: 'Syne', sans-serif;
  font-weight: 400;
  line-height: 25px;
  font-size: 20px;
}

/* @media screen and (max-width: 850px) {
  .gpt__blog-heading {
      margin-bottom: 0;
  }

  .gpt__blog-heading p {
      margin-top: 1rem;
  }
} */

@media screen and (max-width: 850px) {
  .gpt__blog-heading {
      margin-bottom: 0;
  }

  .gpt__blog-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .gpt__blog-feature .gpt__features-container__feature {
      flex-direction: column;
  }

  .gpt__blog-feature .gpt__features-container_feature-text {
      margin-top: 0.5rem;
  }

  .gpt__blog-heading h1 {
      font-size: 24px;
      line-height: 40px;
  }
  .gpt__blog-container {
    width: 100%; 
    margin-left: auto;
    width: 70%;
    margin-top: 50px;
    margin-bottom: 20px
  }
}

@media screen and (max-width: 414px) {
  .gpt__blog-container .gpt__features-container__feature {
      margin: 1rem 0;
      min-width: 100%;
  }

  .gpt__blog {
    padding: 4rem 1rem 4rem 1rem;
  }

  .gpt__blog-feature {
    width: 100% !important;
  }

  .gpt__blog-feature h4{
    font-size: 45px !important;
  }

  .gpt__blog-container {
    text-align: left; 
    margin-right: auto;
    margin-top: .1rem;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 30px !important;

  }

  .gpt__blog-feature h4 {
    margin-top: 1.5vw;
    font-size: 30px;
  }
}



/* Brand  */
.gpt__brand {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.gpt__brand-feature {
  width: 60%;
}

.gpt__brand-feature h4 {
  font-family: 'Syne', sans-serif;
  font-style: normal;
  /* font-size: 90px; */
  font-size: 5.9vw;
  font-weight: 700;
  /* line-height: 100px; */
  line-height: 1.12;
  color: white;
  /* margin-bottom: 70px; */
  margin-bottom: 3.5vw;
  /* margin-top: 100px; */
  margin-top: 6.5vw;
}

.gpt__brand-heading {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: flex-start; 
  margin: 2rem 0; 
}

.gpt__brand-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 100%; 
}

.gpt__brand-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px; 
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
  margin-top: 1rem; 
}

.gpt__brand-container {
  font-size: 'Syne', sans-serif !important;
  font-size: 16px !important;
  font-weight: 250 !important;
  line-height: 30px !important;
  text-align: right !important; 
  color: #ffffff !important;
  width: 100% !important; 
  margin-left: auto !important;
  width: 40% !important;
  margin-top: 70px;
}

.gpt__brand-container h4 {
  font-family: var(--font-family-Medium);
  font-weight: 400;
  line-height: 25px;
  font-size: 20px;
}

.explore__text {
  color: #ffffff;
  text-decoration: underline;
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 500;
}

@media screen and (max-width: 850px) {
  .gpt__brand-heading {
      margin-bottom: 0;
  }

  .gpt__brand-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .gpt__brand-feature .gpt__features-container__feature {
      flex-direction: column;
  }

  .gpt__brand-feature .gpt__features-container_feature-text {
      margin-top: 0.5rem;
  }

  .gpt__brand-heading h1 {
      font-size: 24px;
      line-height: 40px;
  }

  .gpt__brand-container {
      width: 100% !important; 
      margin-left: auto !important;
      width: 70% !important;
      margin-top: 50px;
      margin-bottom: 20px
  }
    
}

@media screen and (max-width: 414px) {
  .gpt__brand-container .gpt__features-container__feature {
      margin: 1rem 0;
      min-width: 100%;
  }
  .gpt__brand-feature {
      width: 100% !important;
  }
  .gpt__brand-container {
      text-align: left !important; 
      margin-right: auto !important;
      margin-top: 0.1rem;
      padding-top: 30px !important;
      width: 100% !important;
      padding-bottom: 30px !important;
  }
  .gpt__brand-feature h4 {
      margin-top: 0;
      font-size: 45px;
  }
  .gpt__brand {
      padding: 4rem 1rem 1rem 1rem;
  }

  .single-image-sec {
    /* padding-top: 0;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem; */
    padding: 0 1rem 0 1rem;
  }
}







/* CTA */

.ulev__inf {
  display: flex;
  flex-direction: column;
}

.ulev__info-feat {
  width: 80%;
}

.ulev__info-feat h4 {
  font-family: 'Syne', sans-serif;
  font-style: normal;
  /* font-size: 90px; */
  font-size: 5.9vw;
  font-weight: 700;
  /* line-height: 100px; */
  line-height: 1.12;
  color: white;
  /* margin-bottom: 70px; */
  margin-bottom: 3.5vw;
  /* margin-top: 100px; */
  margin-top: 6.5vw;
}

.ulev__info-heading {
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: flex-start; 
  margin: 2rem 0; 
}

.ulev__info-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: var(--font-family);
  max-width: 100%; 
}

.ulev__info-heading p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px; 
  line-height: 30px;
  color: var(--color-subtext);
  cursor: pointer;
  margin-top: 1rem; 
}

.ulev__info-contain {
  font-size: 'Syne', sans-serif;
  font-size: 16px;
  font-weight: 250;
  line-height: 30px;
  text-align: right; 
  /* letter-spacing: 0.1em; */
  color: #ffffff;
  width: 100%; 
  margin-left: auto;
  width: 40%;
}


.ulev__info-contain h4 {
  font-family: var(--font-family-Medium);
  font-weight: 400;
  line-height: 25px;
  font-size: 20px;
}

.explore__text {
  color: #ffffff;
  text-decoration: underline;
  font-size: 14px;
  font-family: 'Syne';
  font-weight: 500;
}

@media screen and (max-width: 850px) {
  .ulev__info-heading {
      margin-bottom: 0;
      padding-right: 6rem; 
  }

  .ulev__info-heading p {
      margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .ulev__info-feat .ulev__features-container__feature {
      flex-direction: column;
  }

  .ulev__info-feat .ulev__features-container_feature-text {
      margin-top: 0.5rem;
  }

  .ulev__info-heading h1 {
      font-size: 28px;
      line-height: 40px;
  }

  .ulev__info-contain {
      margin-left: auto;
      width: 70%;
  }
}

@media screen and (max-width: 414px) {
  .ulev__info-contain .ulev__features-contain__feature {
      margin: 1rem 0;
      min-width: 100%;
  }
  .ulev__info-feat {
      width: 100% !important;
  }
  .ulev__info-contain {
      text-align: left !important; 
      margin-right: auto !important;
      margin-top: 0.1rem !important;
      padding-top: 30px !important;
      width: 100% !important;
      margin-bottom: 30px !important;
  }

  .ulev__info-feat h4 {
      margin-top: 0;
      font-size: 45px;
  }
  .ulev__inf {
      padding: 4rem 1rem 4rem 1rem;
  }
}



/* WhatGPT3 */


.gpt3__whatgpt3 {
  display: flex;
  flex-direction: column;
}

.gpt3__whatgpt3-feature h4 {
  font-family: 'Syne', sans-serif;
  font-size: 25px;
  font-weight: 250;
  line-height: 30px;
  letter-spacing: 0;
  color: gray;
}

.gpt3__whatgpt3-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 6rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
  font-size: 34px;
  line-height: 45px;
  font-weight: 800;
  font-family: 'Syne', sans-serif;
  max-width: 510px;
}

.gpt3__whatgpt3-heading p {
  font-family: 'Syne', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
}

.gpt3__whatgpt3-container {
  font-family: 'Syne', sans-serif;
  font-size: 70px;
  font-size: 4.57vw;
  font-weight: 700;
  /* line-height: 70px; */
  text-align: right; 
  letter-spacing: 0;
  color: #ffffff;
  margin-top: 70px;
  line-height: 1.05;
}


.gpt3__whatgpt3-container h4 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 2rem;
}

.explore {
  color: #ffffff;
  font-weight: 400px !important;
  font-size: 25px !important;
  line-height: 25px !important;
}

@media screen and (max-width: 414px) {
  .gpt3__whatgpt3-container {
    text-align: left; 
    width: 100%;
  }
  .gpt3__whatgpt3-container {
      font-size: 30px;
  }
  .gpt3__whatgpt3-container h4 {
      margin-top: 0.5rem !important;
  }
}






