/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Syne&display=swap');
                
@import url('C:\Users\Jon\Desktop\zz\clights.ttf')  format('truetype'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Syne';
  /* src: url('./main_assets/CONSTANTS/Syne-VariableFont_wght.ttf') format('truetype'); */
  src: url('./main_assets/CONSTANTS/static/Syne-Bold.ttf') format('truetype');
  src: url('./main_assets/CONSTANTS/static/Syne-ExtraBold.ttf') format('truetype');
  src: url('./main_assets/CONSTANTS/static/Syne-Medium.ttf') format('truetype');
  src: url('./main_assets/CONSTANTS/static/Syne-Regular.ttf') format('truetype');
  src: url('./main_assets/CONSTANTS/static/Syne-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne-Regular';
  src: url('./main_assets/CONSTANTS/static/Syne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne-Medium';
  src: url('./main_assets/CONSTANTS/static/Syne-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne-ExtraBold';
  src: url('./main_assets/CONSTANTS/static/Syne-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne-SemiBold';
  src: url('./main_assets/CONSTANTS/static/Syne-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne-Bold';
  src: url('./main_assets/CONSTANTS/static/Syne-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* :root {
  --font-family: 'CustomFont', sans-serif; 
} */


:root {
  --font-family: 'Syne', sans-serif;
  --font-family-Regular: 'Syne-Regular', sans-serif;
  --font-family-Medium: 'Syne-Medium', sans-serif;
  --font-family-ExtraBold: 'Syne-ExtraBold', sans-serif;
  --font-family-SemiBold: 'Syne-SemiBold', sans-serif;
  --font-family-Bold: 'Syne-Bold', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #000000;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}
