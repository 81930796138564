.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black; 
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-overlay {
    background-color: rgba(0, 0, 0, 0.9); 
}

.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000; 
    border-radius: 8px;
    padding: 20px;
    max-width: 80%; 
    max-height: 80%; 
    overflow: auto; 
}

.modal-image {
    max-width: 100%; 
    max-height: 100%; 
}

.modal-close-button {
    color: white;
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

/* .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000; 
} */


@media screen and (max-width: 414px) {
    .modal {
        width: 100%;
        height: 100%;
    }
    .modal-image {
        width: 100%;
        height: 100%;
        object-fit: contain; 
    }
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #000; 
        border-radius: 8px;
        padding: 20px;
        max-width: 100%; 
        max-height: 100%;
        width: 100%; 
        height: 100%;
        overflow: auto; 
    }
}
