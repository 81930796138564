.cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 4rem 4rem 4rem 4rem;
}

.ulev__blog-content {
  font-family: 'Syne';
  font-size: 35px;
  font-weight: 400 !important;
  line-height: 34px;
  text-align: left;
  color: white;
  margin-bottom: 40px;
  /* margin-left: 27px; */
  align-self: flex-start;
}

.cards {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important; 
  gap: 20px !important;
  width: 100% !important; 
}

.card-header {
  font-family: 'Syne' !important;
  border: 1px solid gray !important;
  overflow: hidden !important;
  border-radius: 0 !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 25px !important;
  text-align: center !important;
  margin: 0 !important;
  color: white !important;
  transition: transform 0.3s !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  /* width: 100% !important;
  height: 100% !important; */
  width: 100% !important;
  height: 400px !important;
  position: relative;
}

.card-image {
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* .card-header:hover {
  transform: scale(1.05);
  background-color: rgb(255, 255, 255);
  color: #000000;
} */

.card-header:hover {
  transform: scale(1.05);
}

.card-header:hover .card-image {
  display: block; 
  position: absolute; 
  top: 0; 
  left: 0; 
}

.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-description {
  font-size: 16px;
  color: #ffffff;
}

.card-header:hover .card-title,
.card-header:hover .card-description {
  color: #000000;
}

@media screen and (max-width: 1200px) {
  .ulev__blog-content {
    align-self: center;
    margin: 30px;
    text-align: center;
  }
}

@media screen and (max-width: 1050px) {
  .ulev__blog-content {
    font-size: 20px; 
  }
}

@media screen and (max-width: 768px) {
  /* .cards {
    grid-template-columns: repeat(1, 1fr); 
  } */

  .cards {
    grid-template-columns: 1fr !important; 
  }

  .card-title {
    font-size: 20px;
  }

  .card-description {
    font-size: 14px;
  }
  .ulev__blog-content {
    font-size: 24px; 
    padding-bottom: 30px;
  }
  .card-header {
    width: 100% !important;
    height: 40vh !important;
  }
}

@media screen and (max-width: 414px) {
  .cards {
    grid-template-columns: repeat(1, 1fr); 
  }

  .card-title {
    font-size: 18px;
  }

  .card-description {
    font-size: 12px;
  }
  
  .ulev__blog-content {
    text-align: left !important;
    padding-bottom: 20px;
    margin-left: 0;
  }

  .ulev__blog-content h1 {
    font-size: 24px !important;
    text-align: left !important;
    margin-left: 0; 
  }
  .cards-container {
    padding: 1rem 1rem 5rem 1rem !important;
  }
  .card-header {
    width: 100% !important;
    height: 25vh !important;
  }
}
