.ulev__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* .ulev__blog-container_article-image {
    height: 100%;
    height: 100%;

    background: var(--color-footer);
} */

.ulev__blog-container_article-image img {
    width: 100%;
    height: 100%
}

.ulev__blog-container_article-content {
    display: flex;
    flex-direction: column;

    height: 100%;
}



.ulev__blog-container_article-content p {
    font-family: var(--font-family-Medium);
    font-weight: 400px !important;
    font-size: 17px !important;
    line-height: 20px;
    color: #888;
}

.ulev__blog-container_article-content {
    padding-top: 0 !impoFrtant;
}

.ulev__blog-container_article-content .detail h3 {
    font-family: 'Syne';
    font-style: normal; 
    font-weight: 400;
    font-size: 23px;
    line-height: 10px;
    color: #ffffff;

    margin-bottom: 10px;
    cursor: pointer;
}


.address {
    font-family: 'Syne', sans-serif;
    font-style: normal; 
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #888;

    margin-bottom: 1rem;
    cursor: pointer;

}

/* .email {
    font-family: 'Syne';
    font-style: normal; 
    font-weight: 400;
    font-size: 25px;
    line-height: 16px;
    padding-top: 10px;
    color: #888;
    cursor: pointer;
} */

.ulev__blog-container_article-content p:last-child {
    cursor: pointer;
}

.bio-container {
    padding-top: 40px;
}

.bio-container p{
    font-size: 17px;
    line-height: 20px;
}

.more {
    font-family: var(--font-family-Medium);
    font-size: 17px !important;
    line-height: 20px !important;
    text-align: right;
    margin-top: 0 !important;
    font-weight: 400 !important;
}

.harbits{
    padding-top: 50px;
    font-size: 13px;
    line-height: 20px;
}


.harbits strong{
    color: #ffffff !important;
    margin: 50px 0 !important;
}

/* .ulev__blog-container_article-content .details {
    margin-bottom: 50px;
} */

@media screen and (max-width: 768px) {
    .more {
        text-align: left !important;
    }
    .details h5 {
        line-height: 15px;
    }
    
}

@media screen and (max-width: 414px) {
    .ulev__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
    .details h5 {
        line-height: 15px;
    }
    .ulev__blog-container_article-content .details .title {
        line-height: 35px !important;
    }
}