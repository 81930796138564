.ulev__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    padding: 1.5rem 4rem;
    background-color: #000000;
    z-index: 3;
}

.ulev__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.ulev__navbar-links_logo {
    margin-right: auto;
}

.ulev__navbar-links_logo img {
    width: 30px;
    height: 35px;
}

.ulev__navbar-links_container {
    display: flex;
    flex-direction: row;
    margin-left: auto;
}

.ulev__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ulev__navbar-links_container p,
.ulev__navbar-menu_container p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.ulev__navbar-menu {
    margin: 1rem 0 1rem 1rem;
    display: none;
    position: relative;
}

.ulev__navbar-menu svg {
    cursor: pointer;
    margin-right: auto;
}

.ulev__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #2b2a2a;
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.ulev__navbar-menu_container p {
    margin: 1rem 0;
}

.ulev__navbar-menu_conatiner-links-sign {
    display: none;
}

.nav-link {
    color: gray;
    text-decoration: none;
    transition: color 0.3s;
}

.nav-link:hover {
    color: white; 
}

.nav-link.active {
    color: white !important;
}

@media screen and (max-width: 1050px) {
    .ulev__navbar-links_container {
        display: flex;
    }

    .ulev__navbar-menu {
        display: none;
    }
}

@media screen and (max-width: 701px) {
    .ulev__navbar {
        padding: 2rem;
    }
    .ulev__navbar-links_container {
        display: none;
    }

    .ulev__navbar-menu {
        display: flex;
    }
    .ulev__navbar {
        padding: 1.5rem 4rem;
    }
}

@media screen and (max-width: 414px) {
    .ulev__navbar {
        padding: 30px;
    }

    .ulev__navbar {
        padding: 1.5rem 1rem;
    }

    .ulev__navbar-links_container {
        display: none;
    }

    .ulev__navbar-menu {
        display: flex;
    }

    .ulev__navbar-sign,
    /* .ulev__navbar-menu_container {
        display: none;
    } */

    .ulev__navbar-menu_container-links-sign {
        display: block;
    }

    .ulev__navbar-links_logo {
    }

    .ulev__navbar-menu svg {
        margin-left: 0;
        margin-right: 0;
        padding-right: 0
    }

    .ulev__navbar-menu_container {
        background: #2b2a2a; 
        color: white; 
        z-index: 1000; 
    }

    .ulev__navbar-menu_container p {
        margin: 1rem 0;
    }

    /* .ulev__navbar-links_logo img {
        width: 200px;
        height: 40px;
    } */
}
