.sectiona__padding {
    padding: 8rem 4rem 8rem 4rem !important;
}

.sectiona__padding-vid {
    padding: 0rem 4rem 0rem 4rem !important;
}

.sectionass__padding {
    padding: 0 4rem
}

.sectionas__padding {
    padding: 4rem 4rem 4rem 4rem !important;
}

.sectionar__padding {
    padding: 0 4rem 0 4rem !important;
}

.ulev_details {
    position: relative;
}

.ulev__details-title {
    display: flex;
    align-items: center;
}

.ulev__details-title h1 {
    color: white;
    margin-bottom: 0;
    font-family: 'Syne', sans-serif !important;
    font-size: 70px; 
    font-weight: 700;
    line-height: 1.12;
}
.ulev__details-title h2 {
    color: white;
    margin-bottom: 0;
    font-family: 'Syne', sans-serif !important;
    font-weight: 400 !important;
    padding-top: 35px;
    font-size: 18px; 
}

.ulev__details-title p {
    font-family: 'Syne', sans-serif !important;
    font-weight: 400;
    color: gray;
    margin-left: auto;
    text-align: right;
}

.ulev__details-video {
    position: relative;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.video-overlay img {
    max-width: 100%;
    max-height: 100%;
}

.ulev__details-video video {
    max-width: 100%; 
}


.ulev__details-info {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 6rem 0;
}
  
.ulev__details-info p:first-child {
    align-self: end; 
    font-family: 'Syne', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
}

.ulev__details-info p {
    text-align: left;
    color: #888; 
}
  
.ulev__details-info p:nth-child(2),
.ulev__details-info p:nth-child(3) {
    text-align: right;
    padding: 0px 30px 0px 30px;
    font-family: 'Syne', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;

}


.ulev__details-images .image-section {
    display: flex;
    width: 100%;
    max-width: 1700px;
}

.image-section {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.image-section > .single-image-section:first-child {
    margin-right: 10px;
}

.image-section-anne > .image-wrapper-web-anne:first-child {
    margin-right: 10px;
}

.image-section > .single-image-section img {
    height: 500px !important;
}



/** new projects **/

.image-section-new {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.image-section-new > .single-image-section-new:first-child {
    margin-right: 10px;
}

.image-section-new > .single-image-section-new img {
    height: 100% !important;
}



  
.image-wrapper {
    flex: 1;
    width: 50%;
    margin-right: 10px; 
}
  
.image-wrapper:last-child {
    margin-right: 0; 
}
  
.image-wrapper img {
    width: 100%;
    height: 500px !important;
}

.image-wrapper-web img {
    width: 100% !important;
    height: auto !important;
    margin-right: 10px !important; 
}

.image-wrapper-web {
    /* flex: 1; */
    /* width: 50%; */
    margin-right: 10px !important; 
}




.image-section-anne {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    height:  auto;
    margin-bottom: 10px;
}
  
.image-wrapper-anne {
    flex: 1;
    width: 50%;
    margin-bottom: 10px; 
}

.image-wrapper-anne {
    margin-right: 10; 
}
 
  
.image-wrapper-anne img {
    width: 100% !important;
    height: auto !important;
    margin-right: 10px !important;
}


.ulev__details-images {
    display: flex;
    flex-direction: column; 
    justify-content: space-between; 
    max-width: 100%;
    padding: 2rem 1rem;
}
.image-section-anne {
    display: flex;
    flex-direction: row; 
    align-items: center;
}

.image-section-anne > :first-child {
    /* margin-right: 10px; */
}


/* .single-image-section {
    width: 100%;
    margin-bottom: 10px;
} */

.single-image-section {
    width: 100%;
    margin-bottom: 10px;
}

.single-image-section-new {
    width: 100%;
    margin-bottom: 10px;
}

.ulev__details-images .image-section img {
    width: 100% !important;
    align-items : center;
}

.single-image-section img {
    width: 100% !important; 
}

.single-image-section-new img {
    width: 100% !important; 
}


.related-container {
    display: grid;
    flex-wrap: wrap;
    padding-top: 8rem;
}

.related-container .related-card {
    position: relative;
}

  
.related-container .related-image-container {
    position: relative;
    width: 100%;
    height: 350px;
    max-width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    transition: transform 0.3s ease; 
}

.related-container .related-card:hover .related-image-container {
    transform: scale(1.02); 
}

  
.related-container .related-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.related-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: calc(100% - 0px) !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    padding: 30px 0 0 25px;
    opacity: 0.9;
}

.related-container .overlay h2 {
    font-size: 2.5vw;
    font-family: "Syne", sans-serif !important;
}

.related-container .overlay p {
    font-size: 20px !important;
    font-family: "Syne", sans-serif !important;
}
  
.related-container .overlay h2,
.related-container .overlay p {
    margin: 0;
}
  
.related-container .overlay p {
    color: #ffffff;
}
  
.related-container .overlay h2,
.related-container .overlay p {
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
}

.related {
    font-family: "Syne", sans-serif;
    color: #ffffff;
    font-size: 2.5rem;
    padding-bottom: 1.5rem;
    text-align: left;
    line-height: 1.2;
    font-weight: 700px;
    font-style: normal;
}

.ulev_morePD {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    /* padding: 4rem 0; */
  }
  
  .ulev_morePD-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 2rem;
  }
  
  .ulev_morePD-contain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .ulev_morePD-contain p {
    font-family: 'Syne', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    color: #888;
  }
  
  .ulev_morePD-contain {
    display: flex;
  }
  
  .ulev_morePD-contain .ulev_morePD-p {
    flex: 50%;
    color: #888;
    font-size: 16px;
    padding-left: 0px !important;
    text-align: left !important;
  }
  
  .ulev_morePD-contain .ulev_morePD-p h1 {
    flex: 90%;
    color: #ffffff;
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
    text-align: left;
    line-height: 1.2;
    font-weight: 700;
    font-style: normal;
  }

  .ulev_morePD-contain .ulev_morePD-p {
    flex: 90%;
    color: #888;
    font-size: 16px;
    padding-left: 20px;
    text-align: right;
  }

  .ulev_morePD-content p span {
    flex: 90%;
    color: #888 !important;
    font-size: 16px;
    /* padding-left: 20px; */
    text-align: left !important;
  }

  .ulev_morePD-content h1 strong {
    font-family: 'Syne', sans-serif !important;
  }
  
  .ulev_morePD-contain .ulev_morePD-brand {
    flex: 50%;
    color: #ffffff;
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
    text-align: left !important;
    line-height: 1.2;
    font-weight: 700;
    font-style: normal;
  }
  
  .ulev_morePD-contain .ulev_morePD-p2 {
    flex: 40% !important;
    color: #888;
    font-size: 16px;
    padding-left: 20px;
    text-align: left;
  }
  
  .ulev_morePD-contain .ulev_morePD-other {
    flex: 50% !important;
    color: #888;
  }
  
  .ulev_morePD-text {
    display: flex;
  }
  
  .ulev_morePD-text p {
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 30px;
  }
  
  .ulev_morePD-text .first-paragraph {
    flex: 50%;
    color: #888;
    font-size: 16px;
    padding-right: 20px;
  }
  
  .ulev_morePD-text .second-paragraph {
    color: #888;
    font-size: 16px;
    flex: 40%;
    text-align: right;
    line-height: 20px !important;
  }
  
  
  .ulev__details-images-double {
    display: flex;
    justify-content: space-between;
  }

  .ulev__details-videos-double {
    padding: "4rem 4rem 4rem 4rem"
  }
  
  .ulev__details-images .single-image-section {
    flex: 1;
    /* margin: 0 10px; */
  }

  .ulev__details-images .single-image-section-cover {
    flex: 1;
    margin: 0 10px;
  }
  
.body-text {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }

.body-text h1 {
    color: #ffffff;
    font-size: 25px;
    font-family: 'Syne';
    font-weight: 700;


}

.body-text p {
    font-family: 'Syne', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    color: #888;
}


.blurhash-wrapper {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
}

.video-container {
    display: flex;
}
  
.video-wrapper {
    flex: 1;
    margin-right: 10px; 
}
  
.video-wrapper:last-child {
    margin-right: 0; 
}

.full-width-image {
    width: 100%;
}
  
.image-section-new {
    display: flex;
}
  
.fifty-percent-width {
    width: 50%;
}

.single-image-section-new {
    height: 100%;
}
  
  
.section-pair__padding {
    padding: 4rem 4rem;
}

.image__padding {
    padding: 4rem 4rem;
}


@media screen and (max-width: 768px) {

    .ulev__details-title {
        display: flex;
        text-align: left;
    }
    
    .ulev__details-title h1 {
        font-size: 40px;
        text-align: left;
    }
    
    .ulev__details-info {
        grid-template-columns: 1fr;
        text-align: left;
    }
    
    .ulev__details-info p:first-child {
        align-self: flex-start;
    }
    
    .ulev__details-info p:nth-child(2),
    .ulev__details-info p:nth-child(3) {
        text-align: left;
        padding: 0;
    }


    .ulev__details-images .image-section {
        flex-direction: column; 
    }

    .image-section {
        width: 100%; 
        margin-right: 0; 
    }

    .image-section {
        display: block; 
    }
    
    .image-wrapper {
        width: 100%; 
    }

    .image-wrapper img {
        width: 100%;
        height: 100% !important;
    }
    
    .image-section > .single-image-section img {
        height: 100%;
    }

    .image-section-new > .single-image-section-new img {
        height: 100%;
    }

    .video-container {
        max-height: 50vh; 
    }
    
    .video-container video {
        max-height: 50vh; 
    }
}

@media screen and (max-width: 414px) {
    .ulev_details {
        padding-top: 30px;
    }

    .ulev__navbar {
        padding: 1.2rem 1rem;
    }

    .ulev__details-title,
    .ulev__details-info,
    .ulev__details-images {
        padding: 10px;
    }

    .ulev__details-title {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .ulev__details-title h1 {
        font-size: 40px;
        margin-bottom: 10px;
        text-align: left;
        padding-right: 20px;
        line-height: 45px;
    }

    .ulev__details-info p:first-child {
        align-self: flex-start;
    }

    .ulev__details-info p:nth-child(2),
    .ulev__details-info p:nth-child(3) {
        text-align: left;
        padding: 0;
    }

    .ulev__details-images{
        padding: .5rem 1rem !important;
    }

    .ulev__details-image-pair {
        padding: 2rem 1rem !important;
    }

    .ulev__details-images .image-section {
        margin: 0;
    }

    .image-section {
        width: 100%;
        margin-right: 0;
    }

    .gpt3__whatgpt3-container {
        text-align: left; 
    }

    .ulev_morePD-contain {
        padding-right: 0rem;
        padding-left: 0rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    
    .ulev_morePD-contain .ulev_morePD-p {
        flex: 100% !important; 
        text-align: left;
    }
    
    .ulev_morePD-contain .ulev_morePD-p h1{
        flex: 100% !important; 
        text-align: left !important;
        font-size: 30px;
    }

    .ulev_morePD-contain .ulev_morePD-brand {
        flex: 100% !important; 
        text-align: left !important;
    }
    
    .ulev_morePD-contain .ulev_morePD-p2 {
        flex: 100% !important; 
        text-align: left;
    }

    .ulev_morePD-other {
        display: none;
    }
    
    .single-image-section {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
    }

    .single-image-section-new {
        padding-right: 0rem !important;
        padding-left: 0rem !important;
    }

    .related { 
        font-size: 1.7rem;
    }

    .related-container {
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .related-image-container .overlay h5 {
        font-size: 30px !important;
    }  
    
    .related-container .overlay {
        padding: 15px 0 0 12px;
    }

    .related-container .overlay h2 {
        font-size: 28px;
        font-weight: 700px;
        padding-bottom: 10px;
        line-height: 35px;
    }

    .related-container .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%; 
        height: 100%;
        box-sizing: border-box; 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        text-align: left; 
        padding: 30px 20px 0 20px; 
        opacity: 0.9;
        overflow: hidden; 
    }
    
    .sectiona__padding {
        padding: 1rem 1rem !important;
    }

    .sectionas__padding {
        padding: 2rem 1rem 4rem 1rem !important;
    }
    
    .sectionar__padding {
        padding: 0 1rem 0 1rem !important;
    }
    
    .ulev_morePD-contain .ulev_morePD-p {
        width: 100% !important;
        padding-left: 0 !important;
    }
    .section-pair__padding {
        padding: 0rem 4rem !important;
    }

    .ulev__details-image-pair,
  .ulev__details-video-pair {
    flex-direction: column;
  }

  .ulev__details-image-pair .half-image-section,
  .ulev__details-video-pair .half-video-section {
    width: 100%;
    height: 100%;
    /* margin-bottom: 1rem; */
  }

  .sectionass__padding {
    padding: 0 1rem !important; 
    }

    .image__padding {
        padding: 0rem 1rem !important;
    }

    .half-image-section {
        padding: 5px 0px !important;
        height: 100%;
    }


    .half-image-section img {
        height: 100% !important;
    }

    .half-video-section {
        padding: 5px 0px !important;
        height: 100%;
    }

    .sectiona__padding-vid {
        padding: 0rem 1rem 0rem 1rem !important;
    }
    .related-container {
        padding-top: 4rem !important;
    }

}

.ulev__details-image-pair {
    display: flex;
    flex-wrap: wrap;
}
  
.half-image-section {
    flex: 1 1 50%;
    padding: 0px 5px 10px 5px;
    box-sizing: border-box;
}
  
.half-image-section img {
    width: 100%;
    height: auto;
    display: block;
}

/* .half-image-section {
    flex: 1 1 50%;
    box-sizing: border-box;
}

.half-image-section img {
    width: 100%;
    height: auto;
    display: block;
} */

/* .half-image-section:first-child {
    padding-left: 0px !important;
}

.half-image-section:last-child {
    padding-right: 0px;
} */
  
.ulev__details-video-pair {
    display: flex;
    flex-wrap: wrap;
}
  
.half-video-section {
    flex: 1 1 50%;
    padding: 0px 5px 10px 5px;
    box-sizing: border-box;
}
  
.half-video-section video {
    width: 100%;
    height: auto;
    display: block;
}
  
.ulev__details-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  
  .ulev__details-location {
    text-align: right;
  }
  
  .ulev__details-location h4 {
    color: #ffffff;
    margin-bottom: auto;
  }
  
  .ulev__details-location p {
    color: #8a8a8a;
    margin-bottom: auto;
    /* font-size: 14px; */
  }
  

  @media (min-width: 768px) {
    .ulev__details-title-big {
      width: 50vw; 
      max-width: 1200px;
      min-width: 300px;
    }
  }
