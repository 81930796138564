* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: black;
  margin: 0;
  padding: 0;
  cursor: url('U.cur'), auto;
  /* overflow-x: hidden; */
  
}
 
a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {
    background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 4rem;
}

.section__margin {
  padding: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  transition: background-color 0.3s;
}

.back-to-top:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid #ffffff;
}

.arrow-up-icon {
  width: 20px;
  height: 20px;
  fill: white;
  transform: rotate(-90deg); 
  margin-right: 5px;
}

.blurhash-wrapper {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  /* border-radius: 20px; */
  overflow: hidden;
}

 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  .section__padding {
    padding: 4rem;
  }
  /* .section__margin {
    margin: 4rem;
  } */
}

@media screen and (max-width: 414px) {
  /* .section__padding {
    padding: 2rem 1rem !important;
  } */
  .section__padding {
    padding: 4rem 2rem;
  }
  /* .section__margin {
    margin: 4rem 2rem;
  } */
}



/* Ensure default list styles are applied */
ol {
  list-style-type: decimal;
  padding-left: 20px; 
}

ul {
  list-style-type: disc;
  padding-left: 20px; 
}



