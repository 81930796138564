/* .preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ulevus-logo {
  width: 100px; 
  height: auto;
  margin-bottom: 20px; 
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.spinner-inner {
  font-family: "Arial", sans-serif;
  font-size: 10px;
  text-align: center;
  letter-spacing: 2px;
  color: #333;
  padding-top: 5px;
}

.spinner-inner span {
  font-weight: bold;
  color: #3000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */


.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out forwards;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.ulevus-logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}
