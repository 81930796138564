
.gpt__brand {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
}
  
.gpt__brand-feature {
    width: 60%;
}

.gpt__brand-feature h4 {
    font-family: 'Syne', sans-serif;
    font-style: normal;
    /* font-size: 90px; */
    font-size: 5.9vw;
    font-weight: 700;
    /* line-height: 100px; */
    line-height: 1.12;
    color: white;
    /* margin-bottom: 70px; */
    margin-bottom: 3.5vw;
    /* margin-top: 100px; */
    margin-top: 6.5vw;
}
  
.gpt__brand-heading {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: flex-start; 
    margin: 2rem 0; 
}
  
.gpt__brand-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 100%; 
}
  
.gpt__brand-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px; 
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
    margin-top: 1rem; 
}
  
.gpt__brand-container {
    font-size: 'Syne', sans-serif !important;
    font-size: 16px !important;
    font-weight: 250 !important;
    line-height: 30px !important;
    text-align: right !important; 
    color: #ffffff !important;
    width: 100% !important; 
    margin-left: auto !important;
    width: 40% !important;
    margin-top: 70px;
}
  
.gpt__brand-container h4 {
    font-family: 'Syne';
    font-weight: 400;
    line-height: 25px;
    font-size: 20px;
}
  
.explore__text {
    color: #ffffff;
    text-decoration: underline;
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: 500;
}
  
@media screen and (max-width: 850px) {
    .gpt__brand-heading {
        margin-bottom: 0;
    }
  
    .gpt__brand-heading p {
        margin-top: 1rem;
    }
}
  
@media screen and (max-width: 768px) {
    .gpt__brand-feature .gpt__features-container__feature {
        flex-direction: column;
    }
  
    .gpt__brand-feature .gpt__features-container_feature-text {
        margin-top: 0.5rem;
    }
  
    .gpt__brand-heading h1 {
        font-size: 24px;
        line-height: 40px;
    }

    .gpt__brand-container {
        width: 100% !important; 
        margin-left: auto !important;
        width: 70% !important;
        margin-top: 50px;
        margin-bottom: 20px
    }
      
}
  
@media screen and (max-width: 414px) {
    .gpt__brand-container .gpt__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
    .gpt__brand-feature {
        width: 100% !important;
    }
    .gpt__brand-container {
        text-align: left !important; 
        margin-right: auto !important;
        padding-top: 30px !important;
        width: 100% !important;
        padding-bottom: 30px !important;
    }
    .gpt__brand-feature h4 {
        margin-top: 0;
        font-size: 30px;
    }
    .gpt__brand {
        padding: 0 1rem 2rem 1rem !important;
    }
}
  