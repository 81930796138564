.ulev__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* .ulev__blog-container_article-image {
    height: 100%;
    height: 100%;

    background: var(--color-footer);
} */

.ulev__blog-container_article-image img {
    width: 100%;
    height: 100%
}

.ulev__blog-container_article-content {
    display: flex;
    flex-direction: column;

    height: 100%;
}



.ulev__blog-container_article-content p {
    font-family: var(--font-family-Medium) !important;
    font-weight: 400px;
    font-size: 14px;
    line-height: 15px;
    color: #888;
}

.ulev__blog-container_article-content .detail h3 {
    font-family: 'Syne';
    font-style: normal; 
    font-weight: 400;
    font-size: 18px;
    line-height: 10px;
    color: #ffffff;

    margin-bottom: 10px;
    cursor: pointer;
}


.address {
    font-family: 'Syne', sans-serif;
    font-style: normal; 
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #888;

    margin-bottom: 1rem;
    cursor: pointer;

}

.email {
    font-family: 'Syne';
    font-style: normal; 
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: #888;
    cursor: pointer;
}

.ulev__blog-container_article-content p:last-child {
    cursor: pointer;
}

.bio-container {
    padding-top: 40px;
}

.bio-container p{
    font-size: 17px;
    line-height: 20px;
}

.more {
    font-size: 17px !important;
    line-height: 20px !important;
    text-align: right;
    margin-top: 50px;
}

.harbits{
    padding-top: 50px;
    font-size: 13px;
    line-height: 20px;
}


.harbits strong{
    color: #ffffff !important;
    margin: 50px 0 !important;
}

.ulev__blog-container_article-content .details {
    margin-bottom: 50px;
}

.ulev__blog-container_article-content .details .title {
    font-family: var(--font-family-Bold) !important;
    font-style: normal; 
    font-weight: 700;
    /* font-size: 5.9vw !important;
    font-size: 3.5vw !important; */
    line-height: 50px !important;
    color: #ffffff;
    padding-top: 20px;
    font-size: 50px !important;

    margin-bottom: 1rem !important;
    cursor: pointer;
}


/* .ulev__blog-container_article-content .details .title {
    font-family: 'Syne', sans-serif;
    font-style: normal;
    font-size: 4.9vw;
    font-weight: 700;
    line-height: 0.92;
    color: white;
    margin-bottom: 3.5vw;
    margin-top: 6.5vw;
}  */

.ulev__blog-container_article-content .details .text {
    font-family: var(--font-family-Medium) !important;
    font-style: normal !important; 
    font-weight: 400 !important;
    font-size: 17.11px !important;
    line-height: 0 !important;
    color: #888 !important;

    margin-bottom: 1rem !important;
    cursor: pointer !important;
    justify-content: flex-start !important;

}



@media screen and (max-width: 768px) {
    .more {
        text-align: left !important;
    }
    .details h5 {
        line-height: 15px;
    }
    
}

@media screen and (max-width: 414px) {
    .ulev__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
    .details h5 {
        line-height: 15px;
    }
    .ulev__blog-container_article-content .details .text {
        line-height: 17px !Important;
    }

    .ulev__blog-container_article-content .details .title {
        line-height: 50px !important;
        font-size: 40px !important;
    }
}