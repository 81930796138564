.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
}
  
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  

@media screen and (max-width: 414px) {
    .modal {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
    }
    .modal-image {
        width: 100%;
        height: 100%;
        object-fit: contain; 
    }
      
}